<template>
    <CRow>
        <CCol sm="12">
            <div class="row justify-content-between mt-2">
                <div class="col-md-10 col-sm-12 tex-left">
                    <div class="row text-left">
                        <div class="col-auto text-left">
                            <CSelect
                                :options="months"
                                :value="Month"
                                @change="selectMonth($event)"
                            />
                        </div>
                        <div class="col-auto text-left">
                            <CSelect
                                :options="computedYears"
                                :value="year"
                                @change="selectYear($event)"
                            />
                        </div>
                        <div class="col-auto text-left">
                            <p style="margin-top: 0.5rem">{{$t('label.service')}} :</p>
                        </div>
                        <div class="col-3 text-left">
                            <v-select
                                :placeholder="$t('label.select')+$t('label.service')"
                                :options="computedServiceList"
                                class="select-services--vesselIndex"
                                v-model="ServiceModel"
                                @input="selectService"
                            />
                        </div>
                        <div class="col-auto text-left">
                            <p style="margin-top: 0.5rem">{{$t('label.vessel')}} :</p>
                        </div>
                        <div class="col-3 text-left">
                            <v-select
                                :placeholder="$t('label.select')+$t('label.vessel')"
                                :options="computedVesselList"
                                class="select-services--vesselIndex"
                                v-model="VesselModel"
                                @input="selectVessel"
                            >
                                <template slot="option" slot-scope="option">
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            {{ option.label }}
                                        </div>
                                        <div class="col text-right">
                                            <CImg
                                                :src="option.icon"
                                                width="40"
                                                height="25"
                                                class="ml-5"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                        <div class="col-auto" style="margin-top: -0.3rem">
                            <CButton
                                square
                                color="edit"
                                class="d-flex align-items-center"
                                v-c-tooltip="{
                                content: $t('label.reset')+$t('label.table'),
                                placement: 'top',
                                }"
                                @click="refresCalendar"
                            >
                                <CIcon name="cil-loop-circular" />
                            </CButton>
                        </div>
                    </div>
                </div>
            </div>
        </CCol>
        <CCol sm="12" class="halto">
            <full-calendar
                :config.sync="configCallendar"
                :events="computedEvents"
                :header="header"
                @event-drop="eventDrop($event)"
                @event-mouseover="eventMouse($event)"
                :drop="drop"
                @event-selected="eventSelectedCalendar($event)"
                class="calendar-itinerary"
                :key="callendarKey"
                ref="calendar"
                id="calendar"
            />
        </CCol>
    </CRow>
</template>


<script>
    import { FullCalendar } from 'vue-full-calendar';
    import 'fullcalendar/dist/fullcalendar.css';
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import DataStorage from '@/_helpers/localStorage';
    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';
    import moment from 'moment';
    
    const meses = [
        { label: 'ENERO', value: 1 },
        { label: 'FEBRERO', value: 2 },
        { label: 'MARZO', value: 3 },
        { label: 'ABRIL', value: 4 },
        { label: 'MAYO', value: 5 },
        { label: 'JUNIO', value: 6 },
        { label: 'JULIO', value: 7 },
        { label: 'AGOSTO', value: 8 },
        { label: 'SEPTIEMBRE', value: 9 },
        { label: 'OBTUBRE', value: 10 },
        { label: 'NOVIEMBRE', value: 11 },
        { label: 'DICIEMBRE', value: 12 },
    ];

    //data
    function data() {
        return {
            events: [],
            config: {
                locale: this.$i18n.locale,
                defaultView: 'month',
                editable:false,
                eventResize: function(info) {
                    alert(info.event.title + " end is now " + info.event.end.toISOString());

                    if (!confirm("is this okay?")) {
                    info.revert();
                    }
                }
                //events: this.events,
            },
            header: {
                left: "today",
                center: "title",
                right: "listYear,month,agendaWeek"
            },
            VesselId: '',
            Month: '',
            year: '',
            ServiceModel:null,
            VesselModel:null,
            callendarKey: 0,
            drop: false,
            periodo: new Date().getFullYear(),
            yearsSelected: [],
            itemsBuque:[],
            itemsServices:[]
        }
    }
    //methods
    async function selectMonth(event) {
        this.Month = event.target.value;
        this.filterActive = true;
        await this.mountedItinerarioList();
    }
    async function selectYear(event) {
        this.year = event.target.value;
        this.filterActive = true;
        await this.mountedItinerarioList();
    }
    async function checkServicePortList(id) {
        //this.loadingOverlay = true;
        this.OrigitPortId = '';
        this.$store.state.situacionOperativaHistorico.Loading = true;

        await this.$http
            .get(`ServicePort-list?ServiceId=${id}`)
            .then((response) => {
            response.data.data.map((item) => {
                if (item.PortId === this.branch.PortId) {
                this.OrigitPortId = item.ServicePortId;
                }
            });
            if (this.OrigitPortId === '') {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: 'El puerto destino no corresponde al puerto de la sucursal',
                type: 'error',
                });
            }
            })
            .finally(() => {
                this.$store.state.situacionOperativaHistorico.Loading = false;
            })
            .catch((e) => {
                this.$store.state.situacionOperativaHistorico.Loading = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: e,
                    type: 'error',
                });
            });
        }
    async function mountedMont() {
        let date = new Date();
        let month = parseInt(date.getMonth());
        let Day = date.getDate();
        //this.today = `${Day} de ${this.months[month].label} ${date.getFullYear()}`;
        this.Month = month + 1;
        this.year = parseInt(date.getFullYear());
        await this.mountedYear();
        await this.mountedVesselList();
        await this.mountedServiceList();
        await this.mountedItinerarioList();
    }
    async function mountedYear(){
        this.$store.state.situacionOperativaHistorico.Loading = true;

        this.$http
            .get('Year-list')
            .then((response) => (this.yearsSelected = response.data.data))
            .finally(() => {
                this.$store.state.situacionOperativaHistorico.Loading = false;
            })
            .catch((e) => {
                this.$store.state.situacionOperativaHistorico.Loading = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: e,
                    type: 'error',
                });
            });
    }
    async function mountedVesselList() {
        this.$store.state.situacionOperativaHistorico.Loading = true;
        let ruta = 'Vessel-list';

        if ((this.ServiceId!="") && (this.ServiceId!=undefined)) ruta = "ServiceVessel-list?ServiceId="+this.ServiceId;

        this.$http
        .get(ruta)
        .then((response) => (this.itemsBuque = response.data.data))
        .finally(() => {
            this.$store.state.situacionOperativaHistorico.Loading = false;
        })
        .catch((e) => {
            this.$store.state.situacionOperativaHistorico.Loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: 'error',
            });
        });
    }
    async function mountedServiceList() {
        //this.loadingOverlay = true;
        this.$store.state.situacionOperativaHistorico.Loading = true;

        this.$http
        .get('Service-list?CompanyBranchId='+this.branch.CompanyBranchId)
        .then((response) => (this.itemsServices = response.data.data))
        .finally(() => {
            this.$store.state.situacionOperativaHistorico.Loading = false;
        })
        .catch((e) => {
            this.$store.state.situacionOperativaHistorico.Loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: 'error',
            });
        });
    }
    function computedYears() {
        if(this.yearsSelected.length === 0){
            return [{
                value: parseInt(this.year), 
                label: '',
            }];
        }else{
            var chart = [];
            this.yearsSelected.map(function(e){
                chart.push({
                    value: e.Year, 
                    label: '',
                })
            })
            return chart;
        }
    }
    function rangoFecha(yearsSelected) {
        let beginningYear = yearsSelected.length!=0?yearsSelected[0].Year:2022;
        let EndYear = yearsSelected.length!=0?yearsSelected[yearsSelected.length-1].Year:2022;
        var anioAtras = new Date(beginningYear,0,1);
        var anioSiguiente = new Date(EndYear,11,31);
        
        return {
            start: anioAtras,
            end: anioSiguiente
        };
    }
    async function mountedItinerarioList() {
        this.$store.state.situacionOperativaHistorico.Loading = true;

        this.config = await localStorage.getItem('user');
        this.config = JSON.parse(this.config);
        if (typeof(this.ServiceId)=="undefined") this.ServiceId = "";
        if (Object.keys(this.config).length !== 0) {
            this.CompanyBranchId = this.config.BranchJson[0].CompanyBranchId;
        }

        this.$http
        .get(
            `VisitItineraryHist-list-by-year-month?CompanyBranchId=${this.CompanyBranchId}&VesselId=${this.VesselId}&ServiceId=${this.ServiceId}&Year=${this.year}&Month=${this.Month}`
        )
        .then((response) => {
            this.events = response.data.data;
            let mes = this.Month;
            if (mes < 10) mes = '0'+this.Month;
            this.$refs.calendar.fireMethod('gotoDate', this.year+'-'+mes+'-01');
        })
        .finally(() => {
            this.$store.state.situacionOperativaHistorico.Loading = false;
        })
        .catch((e) => {
            this.$store.state.situacionOperativaHistorico.Loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: 'error',
            });
        });
    }
    async function selectService(payload) {
        if (payload !== null) {
            this.ServcieModel = payload.label;
            this.ServcieId = payload.value;
            this.filterActive = true;
            await this.mountedItinerarioList();
        } else {
            this.ServcieModel = '';
            this.ServcieId = '';
        }
    }   
    async function selectVessel(payload){
        if (payload !== null) {
            this.VesselModel = payload.label;
            this.VesselId = payload.value;
            this.filterActive = true;
            await this.mountedItinerarioList();
        } else {
            this.VesselModel = '';
            this.VesselId = '';
        }
    }
    async function refresCalendar() {
        await this.mountedMont();
        this.VesselModel = '';
        this.VesselId = '';
        this.ServiceId = '';
        this.filterActive = false;
        this.drop = false;
        this.$store.state.situacionOperativaHistorico.collapseHistorico = false;
    }
    async function eventDrop(event) {
        const arrayIntinerary = await this.events.filter(
            (element) => element.ItineraryId === event.id
        );

        await this.checkServicePortList(arrayIntinerary[0].ServiceId);
    }
    async function eventMouse(event){
        tippy("#"+event.classNames, {
            getReferenceClientRect: null,
            content: event.description,
            animation: 'scale',
            theme: 'bluesimple',
        });
    }
    function eventSelectedCalendar(event) {
        const arrayIntinerary = this.events.filter(
            (element) => element.ItineraryId === event.id
        );
        this.$store.state.situacionOperativaHistorico.VisitId = arrayIntinerary[0].VisitId ? arrayIntinerary[0].VisitId : '';
        if(this.$store.state.situacionOperativaHistorico.VisitId !== ''){
            this.$store.state.situacionOperativaHistorico.itinerarySelected = arrayIntinerary[0];
            //this.$store.state.situacionOperativaHistorico.bulkCarrier = arrayIntinerary[0].TpVesselId.toUpperCase()==process.env.VUE_APP_BULK_VESSEL_ID ? 2 : 1;
            this.$store.state.situacionOperativaHistorico.FgGeneralCargo = arrayIntinerary[0].TpVesselId.toUpperCase() === process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
            this.$store.state.situacionOperativaHistorico.FgContainerShip = arrayIntinerary[0].TpVesselId.toUpperCase() === process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID;
            this.$store.state.situacionOperativaHistorico.FgBulk = arrayIntinerary[0].TpVesselId.toUpperCase() === process.env.VUE_APP_BULK_VESSEL_ID;
            this.$store.state.situacionOperativaHistorico.collapseHistorico = true;
        }else{
            this.$store.state.situacionOperativaHistorico.collapseHistorico = false;
        }
    }
    //### computed
    function months(){
        return [
            { label: this.$t('label.january'), value: 1 },
            { label: this.$t('label.february'), value: 2 },
            { label: this.$t('label.march'), value: 3 },
            { label: this.$t('label.april'), value: 4 },
            { label: this.$t('label.may'), value: 5 },
            { label: this.$t('label.june'), value: 6 },
            { label: this.$t('label.july'), value: 7 },
            { label: this.$t('label.august'), value: 8 },
            { label: this.$t('label.september'), value: 9 },
            { label: this.$t('label.octuber'), value: 10 },
            { label: this.$t('label.november'), value: 11 },
            { label: this.$t('label.december'), value: 12 },
        ];
    }
    function computedVesselList() {
        if (this.itemsBuque.length > 0) {
            return this.itemsBuque.map((item) => {
                return {
                    label: item.VesselName,
                    value: item.VesselId,
                    icon:
                    ((item.Icon !== '') && (item.Icon !== undefined) && (item.Icon !== null))
                        ? `${this.$store.getters["connection/getBase"]}${item.Icon}`
                        : `${this.$store.getters["connection/getBase"]}${item.VesselFlagRoute}`,
                };
            });
        }
    }
    function computedServiceList() {
        if (this.itemsServices.length > 0) {
            return this.itemsServices.map((item) => {
                return {
                    label: item.ServiceCode,
                    value: item.ServiceId,
                };
            });
        }
    }
    function today(){
        let date = new Date();
        let month = parseInt(date.getMonth());
        let Day = date.getDate();
        return `${this.months[month].label} ${Day}, ${date.getFullYear()}`;
    }
    function computedEvents() {
        if (this.events.length > 0) {
            let local = DataStorage.getLocale();
            return this.events.map((item,index) => {
                let DepartureCalendar = moment(item.DepartureCalendar.replace('T', ' ').replace('Z', '')).format('YYYY-MM-DD HH:mm')
                if (Number.parseInt(moment(DepartureCalendar).format('HH')) < 9) {
                    DepartureCalendar = moment(DepartureCalendar).add(12, 'h').format('YYYY-MM-DD HH:mm');
                }
                return {
                    id: item.ItineraryId,
                    title: `${item.VesselName}, ${item.Voyage}, ${item.LabelArrival} ${DateFormater.formatDateTimeWithSlash(item.Arrival)} - ${item.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(item.Departure)} `,
                    start: item.Arrival,
                    end: DepartureCalendar,
                    backgroundColor: item.Color,
                    textColor: '#F0F0F0',
                    editable: false,
                    classNames: "cambio-especial"+index,
                    borderColor: item.Color,
                    description: item.VisitId ? (local == 'es'? item.VisitStatusEs : item.VisitStatusEn) : (local == 'es'? item.ValueStatusDs : item.ValueStatusDsEn)
                };
            });
        }
    }
    function configCallendar() {
        let local = DataStorage.getLocale();
        let sendCAllendarConfig = {
            locale: local,
            defaultView: 'month',
            editable:false,
            //events: this.events,
            eventRender: function(event, element){
                element[0].id = event.classNames;
            },
            validRange: rangoFecha(this.yearsSelected)
        };
        return sendCAllendarConfig;
    }
    //watch
    async function collapseHistorico(newValue){
        if(newValue === false){
            this.mountedMont();
        }
    }
    export default {
        name:'calendar-visita',
        data,
        mounted(){
            this.$nextTick(async () => {
                if(this.collapseHistorico === false){
                    await this.mountedMont();
                }
            });
        },
        components: {
            tippy,
            FullCalendar
        },
        methods:{
            mountedYear,
            mountedMont,
            selectMonth,
            selectYear,
            selectService,
            selectVessel,
            refresCalendar,
            rangoFecha,
            eventDrop,
            eventMouse,
            eventSelectedCalendar,
            checkServicePortList,
            mountedItinerarioList,
            mountedVesselList,
            mountedServiceList
        },
        computed:{
            months,
            today,
            computedYears,
            computedVesselList,
            computedServiceList,
            computedEvents,
            configCallendar,
            ...mapState({
                branch: (state) => state.auth.branch,
                user: state => state.auth.user,
                VisitId: state => state.situacionOperativaHistorico.VisitId,
                tabsOperationes: state => state.situacionOperativaHistorico.tabsOperationes,
                collapseHistorico: state => state.situacionOperativaHistorico.collapseHistorico,
            }),
        },
        watch: {
            collapseHistorico,
            configCallendar: function(nuevo) {  
                this.callendarKey+= 1;
                this.mountedItinerarioList();
            },
        }
    }
</script>

<style lang="scss">
    .halto-servicio {
        overflow-y: auto;
    }
    .select-services--vesselIndex .vs__dropdown-menu {
        max-height: 280px !important;
    }
    .calendar-itinerary {
        .fc-content,
        .fc-list-item {
            cursor: pointer !important;
        }
        table {
            tbody {
                table {
                    tbody {
                        tr {
                            .fc-today {
                            background: lightgrey !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .tippy-box[data-theme~='bluesimple'] {
        fill: #6ca3d7;
        color:#606a87;
    }
</style>